<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <weather />
        <div class="body-box">
          <!-- 第三行数据 -->
          <el-row :gutter="16">
            <el-col :sm="24" :lg="4">
              <div class="side-box">
                <img src="../assets/images/ycww.svg" alt="" height="754" />
              </div>
            </el-col>
            <el-col :sm="24" :lg="16">
              <div class="center-box">
                <centerLeft2 :isOpen="false" />
                <div class="bottm-btns">
                  <div
                    class="btn"
                    @click="changeBtn(index)"
                    v-for="(item, index) in btnList"
                    :key="item">
                    <div>
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="24" :lg="4">
              <div class="side-box">
                <img src="../assets/images/jsql.svg" alt="" height="754" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from '../utils/drawMixin'
import centerLeft2 from './centerLeft2'
import weather from './components/weather'

export default {
  mixins: [drawMixin],
  components: {
    centerLeft2,
    weather
  },
  data() {
    return {
      loading: true,
      btnList: ['力量部署', '应急指挥', '应急演练', '视频调度']
    }
  },
  provide() {
    return { isShow: true }
  },
  mounted() {
    this.cancelLoading()
  },
  beforeDestroy() {},
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    changeBtn(index) {
      if (index == 0) {
        this.$router.push({
          path: '/powerDeployment'
        })
        // const { href } = this.$router.resolve({
        //   path: '/powerDeployment'
        // })
        // window.open(href, '_blank')
      } else if (index == 1) {
        this.$router.push({
          path: '/emergencyCommand'
        })
        // const { href } = this.$router.resolve({
        //   path: '/emergencyCommand'
        // })
        // window.open(href, '_blank')
      } else if (index == 2) {
        console.log(index)
      } else {
        console.log(index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.side-box {
  height: 950px;
  display: flex;
  justify-content: center;
  & > img {
    // width: 156px;
    margin-top: 30px;
  }
}
.center-box {
  display: flex;
  justify-content: center;
  margin-top: -110px;
  .bottm-btns {
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    margin-top: 15px;
    width: 100%;
    height: 190px;
    background-image: url('../assets/images/bottom_btn.png');
    background-size: 100% 100%;
    .btn {
      color: #00a3ff;
      margin: 104px 49px 0 49px;
      text-align: center;
      cursor: pointer;
    }
    .btn:hover {
      color: #fff;
    }
  }
}
</style>
